import * as React from "react"

import { settingsStore } from "@/store/settings"

// interface Props {}

export function ColorBlind() {
  const theme = settingsStore((state) => state.theme)
  const colorBlindMode = settingsStore((state) => state.colorBlindMode)

  React.useEffect(() => {
    if (colorBlindMode) {
      document.querySelector("body")!.classList.add("colorblind")
    } else {
      document.querySelector("body")!.classList.remove("colorblind")
    }

    const darkThemeMQ = window.matchMedia("(prefers-color-scheme: dark)")
    if (theme === "dark" || (theme == "system" && darkThemeMQ.matches)) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }, [theme])

  React.useEffect(() => {
    if (colorBlindMode) {
      document.querySelector("body")!.classList.add("colorblind")
    } else {
      document.querySelector("body")!.classList.remove("colorblind")
    }
  }, [colorBlindMode])

  return null
}
